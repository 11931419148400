import React from 'react';
import { motion } from 'framer-motion';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useLocation } from '@reach/router';
import { encode } from '@lib/helpers';

import { ModuleWrapper } from '../moduleWrapper';
import { PortableTextBlock } from '@components/sanity/portableTextBlock';

const ContactForm = ({ config, details }) => {
  const { pathname } = useLocation();
  return (
    <ModuleWrapper {...config}>
      <div className="px-gutter grid grid-cols-1 py-10 gap-y-4 md:grid-cols-2">
        <div>
          <PortableTextBlock text={details} />
        </div>
        <div>
          <Formik
            initialValues={{
              fullName: '',
              email: '',
              phone: '',
              message: '',
            }}
            validate={(values) => {
              const errors = {};
              if (!values.fullName) {
                errors.fullName = 'Full Name is Required';
              }
              if (!values.email) {
                errors.email = 'Email address is Required';
              } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = 'Invalid email address';
              }
              if (!values.phone) {
                errors.phone = 'Contact Number is Required';
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, setStatus }) => {
              fetch(pathname || '/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: encode({
                  'form-name': `contact-form`,
                  ...values,
                }),
              })
                .then(() => {
                  setSubmitting(false);
                  setStatus('success');
                })
                .catch((error) => alert(error));
            }}
          >
            {({
              onChange,
              isSubmitting,
              status,
              values,
              /* and other goodies */
            }) => (
              <Form
                name="contact-form"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                subject="Website Enquiry"
              >
                {/* Hidden Fields for netlify */}
                <input type="hidden" name="form-name" value="contact-form" />
                <p hidden>
                  <label>
                    <input name="bot-field" onChange={onChange} />
                  </label>
                </p>
                {status === 'success' ? (
                  <div className="col-span-full text-left">
                    <motion.h2
                      className="font-display text-2xl"
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                    >
                      Thank you we will be in touch.
                    </motion.h2>
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col gap-y-4">
                      <div className="w-full">
                        <Field
                          type="text"
                          name="fullName"
                          placeholder="Full Name"
                          className="standard-input"
                        />
                        <ErrorMessage name="fullName" className="text-sm my-2" component="div" />
                      </div>
                      <div>
                        <Field
                          type="email"
                          name="email"
                          placeholder="Email"
                          className="standard-input"
                        />
                        <ErrorMessage name="email" className="text-sm my-2" component="div" />
                      </div>
                      <div>
                        <Field
                          type="text"
                          name="phone"
                          placeholder="Contact Number"
                          className="standard-input"
                        />
                        <ErrorMessage name="phone" className="text-sm my-2" component="div" />
                      </div>
                      <Field
                        type="text"
                        name="referral"
                        placeholder="How did you hear about us ?"
                        className="standard-input"
                      />
                      <Field
                        type="text"
                        name="comments"
                        placeholder="Comments"
                        component="textarea"
                        className="standard-input"
                      />
                      <button className="self-start btn large" type="submit">
                        Submit
                      </button>
                    </div>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default ContactForm;
